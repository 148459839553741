/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Roboto+Slab:wght@700&display=swap");

:root{
  --tofel-background-color:#f9f9f9;
  --cc_load_click: block;
}

html,
body {
  height: 100%;
  padding-top: 0;
  color: #151515;
  font-family: "Open Sans", sans-serif;
  background-color: var( --tofel-background-color) !important;
}

@font-face {
  font-family: 'Greet Narrow';
  src: url('assets/fonts/greet-narrow/GreetNarrowVF.woff') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Greet Standard';
  src: url('assets/fonts/greet-standard/GreetStandardVF.woff') format('truetype');
  font-weight: normal;
  font-style: normal;
}


/* main {
    position: relative;
    min-height: calc(100vh - 300px);
} */
/* 
footer {
    font-family: 'Open Sans', Font Awesome\ 5 Pro, sans-serif;
    padding: 0 10px 10px;
    color: #151515;
    background-color: #f2f2f2;
    bottom: 0;
    width: 100%;
    height: 165px;
} */

.required-asterisk {
  color: #c13145;
}

h1 {
  color: #3072be;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}
h1,
h2,
h3,
h4 {
  font-family: "Open Sans", sans-serif;
}

h1.title {
  font-size: 1.5rem;
  color: #003082;
  font-weight: 700;
}
h2 {
  font-size: 20px;
  font-weight: 700;
}
h3 {
  font-size: 20px;
  font-weight: 700;
}

.select-payment-title h3 {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.7em;
  font-weight: 400;
  color: #003356;
}

a.link {
  color: #717171;
  text-decoration: none;
}
a.link:hover {
  text-decoration: underline;
}
.container-fluid {
  max-width: 1330px;
}

@media (max-width: 576px) {
  .select-payment-title h3 {
    font-size: 1.5em;
  }
}

.loader {
  position: relative;
  width: auto;
  height: auto;
  z-index: 100;
  top: 50vh;
  background-color: rgba(0, 0, 0, 0.2);
}

.form-select,
.form-control {
  border: 0.5px solid #878787;
}

input:focus{
  border: 5px double black !important;
  box-shadow: 1px 1px 1px black !important;
}

select:focus{
  border: 5px double black !important;
  box-shadow: 1px 1px 1px black !important;
}

mdn-select:focus{
  border: 5px double black !important;
  box-shadow: 1px 1px 1px black !important;
}


.disabled-bkg {
  background: rgba(229, 229, 229, 1);
  position: relative;
  color: #333;
  border-radius: 150px;
  font-family: "Open Sans", sans-serif;
}

.btn--primary {
  position: relative;
  background: rgba(242, 195, 0, 1);
  color: #333;
  border-radius: 150px;
  font-family: "Open Sans", sans-serif;
}

.processing-button {
  border: none;
  padding: 10px 22px;
  margin: 10px 0;
  font-weight: 600;
}

.lock {
  float: left;
  height: 100%;
  margin-right: 9px;
  color: grey;
}

.sub-text {
  font-size: 80%;
}

fieldset,legend {
  margin: 0;
  padding: 0;
  width: auto;
  display: block;
  float: none !important;
}